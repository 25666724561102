import React from "react";
import styled from "styled-components";

// assets
import Image from "../../assets/aboutimg.png";

function About() {
  return (
    <Head id="about">
      <Container>
        <Img src={Image} />
        <SubContainer>
          <Title><Span>:</Span>ABOUT</Title>
          <P>
            In a strange turn of events, Pepe the frog traveled back in time to
            inscribe his memes as ordinals under the first 1,000 inscriptions on
            the Bitcoin blockchain. His mission was to send a warning to the
            present-day world about the dystopian society he came from, where
            memes have become symbols of resistance against the oppressive
            regime.
          </P>
          <P>
            Pepe's inscriptions on the blockchain are a chilling reminder of the
            consequences of unchecked power and the importance of protecting the
            freedom and individuality of all people. The world he came from
            serves as a cautionary tale, a warning to all of us to be mindful of
            the way technology is used and the impact it can have on our lives
            and society.
          </P>
        </SubContainer>
      </Container>
    </Head>
  );
}

export default About;

// styles

const Head = styled.div`
  margin: 0 auto;
  padding-top: 160px;
  max-width: 1400px;
  width: 100%;
  position: relative;
  @media (max-width: 1146px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1146px) {
    flex-direction: column-reverse;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }
  @media (max-width: 1436px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 1436px) {
    width: 100%;
    margin-bottom: 45px;
  }
`;

const Img = styled.img`
  width: 600px;
  height: auto;
  @media (max-width: 1136px) {
    width: 100%;
    height: auto;
  }
`;

const Title = styled.h2`
  font-family: Glass;
  font-size: 64px;
  margin: 0;
  @media (max-width: 526px) {
    font-size: 48px;
  }
  @media (max-width: 402px) {
    font-size: 40px;
  }
`;

const Span = styled.span`
  color: #D400FE;
  margin-right: 15px;
`

const P = styled.p`
  font-size: 18px;
  font-weight: 500;
  padding-right: 70px;
  margin: 20px 0;
  @media (max-width: 1146px) {
    padding-right: 0px;
  }
`;