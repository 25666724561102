import React from "react";
import styled from "styled-components";

// assets
import Pattern2 from "../../assets/pattern2.svg";

function BottomPattern() {
  return <Container>
    <Img src={Pattern2}/>
  </Container>;
}

export default BottomPattern;

const Container = styled.div`
  position: relative;
`;

const Img = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  background-position-x: -710px;
  background-repeat: repeat-x !important;
`;
