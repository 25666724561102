import React from "react";
import styled from "styled-components";


function Navigation() {
  return (
    <Head>
      <Container>
        <StyledText href="#"><Span>:</Span>Home</StyledText>
        <StyledText href="#about"><Span>:</Span>About</StyledText>
        <StyledText href="#inscription"><Span>:</Span>Inscriptions</StyledText>
      </Container>
    </Head>
  );
}

export default Navigation;

// styles

const Head = styled.div`
  marign: 0 auto;
  z-index: 100;
  position: relative;
`;

const Container = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  right: 0;
  background-color: #dfdfdf;
  height: 50px;
  @media (max-width: 651px) {
    justify-content: center;
    left: 0;
  }
  @media (max-width: 504px) {
    width: 100%;
  }
`;

const StyledText = styled.a`
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
  padding: 25px;
  cursor: pointer;
  color: #000000;
  transition: 200ms smooth;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 504px) {
    font-size: 20px;
    padding: 15px;
  }
  @media (max-width: 384px) {
    font-size: 18px;
    padding: 12px;
  }
`;

const Span = styled.span`
  color: #D400FE;
`