import React from "react";
import styled from "styled-components";

// assets
import Image from "../../assets/headerimg.png";
import Pattern from "../../assets/pattern.svg";

function Landing() {
  return (
    <Container>
      <Title>
        ORDINAL CYBER<Span>PEPES</Span>
      </Title>
      <Img src={Pattern} />
    </Container>
  );
}

export default Landing;

// styles

const Container = styled.div`
  height: 70vh;
  width: 100%;
  position: relative;
  background: url(${Image}) 0 0 no-repeat;
  background-size: cover;
  background-position: center;
`;

const Title = styled.h1`
  font-family: Glass;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  font-size: 96px;
  color: #dfdfdf;
  padding-left: 80px;
  margin-bottom: 150px;
  @media (max-width: 725px) {
    font-size: 12vw;
    padding-left: 25px;
  }
`;

const Span = styled.span`
  color: #53b700;
`;


const Img = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-position-x: -710px;
  background-repeat: repeat-x !important;
`;
