import React from "react";
import styled, { css } from "styled-components";

// assets
import _934 from "../../assets/pepes/934.png";
import _935 from "../../assets/pepes/935.png";
import _936 from "../../assets/pepes/936.png";
import _937 from "../../assets/pepes/937.png";
import _938 from "../../assets/pepes/938.png";
import _939 from "../../assets/pepes/939.png";
import _940 from "../../assets/pepes/940.png";
import _941 from "../../assets/pepes/941.png";
import _942 from "../../assets/pepes/942.png";
import _943 from "../../assets/pepes/943.png";

function Inscriptions() {
  return (
    <Head id="inscription">
      <Title>
        <Span>:</Span>INSCRIPTIONS
      </Title>
      <P>Total Supply: 10</P>
      <P>Highest Inscription: #943</P>
      <Container>
        <a
          href="https://ordinals.com/inscription/bcee2a5636d9ceb14ba5582a841a3fc5c49cec8bc965f1486c73b78ae5ac2349i0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_934} />
            <P>#934</P>
          </ImgDiv>
        </a>
        <a
          href="https://ordinals.com/inscription/38eb03d9828599859f8fb4db6938c5decc2917420cb3bac5e8ca2e9009d0cd60i0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_935} />
            <P>#935</P>
          </ImgDiv>
        </a>
        <a
          href="https://ordinals.com/inscription/4e19bc47a304e3cd1321d7e41a9dff3b755a660d81b847825693e392da37866ai0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_936} />
            <P>#936</P>
          </ImgDiv>
        </a>
      </Container>
      <Container>
        <a
          href="https://ordinals.com/inscription/d15a0b7911b4e55bf007096d70057e8ffc53c473b856de35690ca3ce07a68b75i0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_937} />
            <P>#937</P>
          </ImgDiv>
        </a>
        <a
          href="https://ordinals.com/inscription/a84a11883e7e5e5fdf862d8839f6d9f110e56494fcc498e0859320d17d13538ei0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_938} />
            <P>#938</P>
          </ImgDiv>
        </a>
        <a
          href="https://ordinals.com/inscription/51da6723ef8b162eb9b7f2149d987a924c346e3335a1421e57c7f782747495f7i0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_939} />
            <P>#939</P>
          </ImgDiv>
        </a>
      </Container>
      <Container>
        <a
          href="https://ordinals.com/inscription/5e1399de346840847f21e99b220c5b4346d823d92c6f1d4066eff0f959087f52i0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_940} />
            <P>#940</P>
          </ImgDiv>
        </a>
        <a
          href="https://ordinals.com/inscription/caa039a22f2d4ec3b01c5025e6e238c85f48f5f2a16dc6deeb3b425610565669i0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_941} />
            <P>#941</P>
          </ImgDiv>
        </a>
        <a
          href="https://ordinals.com/inscription/4b4e254f1a59d9e31e0641c9ad2cc6f6d904d2abf2e98b52273e1dc6fe4be376i0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_942} />
            <P>#942</P>
          </ImgDiv>
        </a>
      </Container>
      <Container center>
        <a
          href="https://ordinals.com/inscription/0d307718368b8e35f4d646f93e7aa4045f41b47c2778f331595f9a786b5b505ai0"
          target="_blank"
          rel="noreferrer"
        >
          <ImgDiv>
            <Img src={_943} />
            <P>#943</P>
          </ImgDiv>
        </a>
      </Container>
    </Head>
  );
}

export default Inscriptions;

// styles

const Head = styled.div`
  margin: 0 auto;
  padding-top: 150px;
  max-width: 1400px;
  width: 100%;
`;

const Container = styled.div`
  padding-top: 50px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1436px) {
    justify-content: center;
  }
  @media (max-width: 1146px) {
    flex-direction: column;
  }
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}
`;

const Title = styled.h2`
  font-family: Glass;
  font-size: 64px;
  margin: 0;
  @media (max-width: 1436px) {
    padding-left: 25px;
  }
  @media (max-width: 526px) {
    font-size: 48px;
  }
  @media (max-width: 402px) {
    font-size: 40px;
  }
`;

const Span = styled.span`
  color: #d400fe;
  margin-right: 15px;
`;

const P = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0;
  @media (max-width: 1436px) {
    padding-left: 25px;
  }
`;

const ImgDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  width: 97%;
  height: auto;
  @media (max-width: 1436px) {
    width: 95%;
  }
`;
