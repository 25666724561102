import React from "react";
import styled from "styled-components";

// Components
import Navigation from "./components/Navigation";
import Landing from "./components/Landing";
import BottomPattern from "./components/Pattern";
import About from "./components/About";
import Inscriptions from "./components/InscriptionSection";
import Footer from "./components/Footer";



function App() {
  return (
    <Container>
      <Navigation/>
      <Landing/>
      <BottomPattern/>
      <About/>
      <Inscriptions/>
      <Footer/>
    </Container>
  );
}

export default App;

// styles

const Container = styled.div`

`
