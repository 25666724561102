import React from "react";
import styled from "styled-components";

// assets
import Twitter from "../../assets/twitterfooter.svg";
import Discord from "../../assets/discordfooter.svg";

function Footer() {
  return (
    <Head>
      <Container>
        <a href="https://twitter.com/finestrares" target="_blank">
          <ImgDiv>
            <Img src={Twitter} />
          </ImgDiv>
        </a>
        <a href="https://discord.gg/39xqUmDMxq" target="_blank">
          <ImgDiv>
            <Img src={Discord} />
          </ImgDiv>
        </a>
      </Container>
    </Head>
  );
}

export default Footer;

// styles

const Head = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  padding-top: 80px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

const ImgDiv = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const Img = styled.img`
  transition: 250ms smooth;
  &:hover {
    opacity: 0.8;
  }
`;
